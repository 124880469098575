import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { Store } from '../../../Services';

import Permission from '../../../helper/Permission';

import Actions from './actions';
import ControlButtons from './ControlButtons';
import ContentComponent from './ContentComponent';

import { assessmentDefault } from '../../../components/Assessment';

import '../../../components/admin/ElementPage.scss';

export default ({ _id }) => {
  const { state } = useContext(Store);

  const permission = useMemo(() => Permission.has(state.role), [state.role]);
  const role = useMemo(() => (permission.put('contents/status') ? 'editor' : 'auditor'), [permission]);

  const [content, _setContent] = useState();
  const [controlButtonList, setControlButtonList] = useState();
  const [loading, setLoading] = useState(false);
  const [enabled, setEnabled] = useState(true);

  const setter = useCallback((data, loadContext) => {
    _setContent(prevElm => {
      const newContent = { ...(prevElm || {}), ...data };
      if (loadContext) {
        setControlButtonList(ControlButtons.filter(permission, newContent, role));
        setEnabled(
          (role === 'editor' && (newContent.status === 'Revision' || newContent.status === 'Rehabilitacion')) ||
            newContent.status === 'Pendiente' ||
            (!newContent.status && !newContent._id)
        );
      }
      return newContent;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = useCallback(async () => {
    const exec = async () => {
      const _content = await Actions.getContent(_id);
      setter(
        {
          ..._content,
          dateTime: _content.dateTime ? new Date(_content.dateTime) : undefined
        },
        true
      );
    };

    if (_id) {
      await exec();
    } else {
      setter({ dateTime: new Date(), assessment: assessmentDefault, status: 'Pendiente' }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const exec = async () => {
      const _content = await Actions.getContent(_id);
      setter(
        {
          ..._content,
          dateTime: _content.dateTime ? new Date(_content.dateTime) : undefined
        },
        true
      );
    };

    if (_id) {
      exec();
    } else {
      setter({ dateTime: new Date(), assessment: assessmentDefault, status: 'Pendiente' }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);

  return (
    <>
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
          <div className="loader-text">
            <p>CARGANDO, por favor espere</p>
          </div>
        </div>
      )}
      <Container className="app-element-page" fluid>
        <Row className="panel">
          <Col xs="12" md="12">
            <Row>{content && <ContentComponent {...{ content, role, setter, disabled: !enabled || loading }} />}</Row>
          </Col>
        </Row>

        <ControlButtons {...{ content, controlButtonList, setLoading, loading, onRefresh: refresh, enabled, role }} />
      </Container>
    </>
  );
};
