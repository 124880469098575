import _ from 'lodash';
import React from 'react';

import { Row, Col, Form } from 'react-bootstrap';

export const destinationList = {
  alert: 'Alertas',
  inform: 'Informes',
  compiles: 'Compilados',
  tapes: 'Desgrabaciones',
  synthesis: 'Síntesis de Prensa'
};

const destinations = [
  destinationList.alert,
  'Resumen',
  destinationList.compiles,
  destinationList.tapes,
  destinationList.synthesis
];

const destinationEnableList = {};

const verifyEnable = (value, destination) => {
  if (value.length === 0 || _.indexOf(value, destination) >= 0) return true;
  if (!destinationEnableList[destination]) return false;
  return _.isEqual(value, destinationEnableList[destination]);
};

export default ({ disabled, value = [], onChange }) => {
  return (
    <Row>
      <Col xs="12">
        {_.map(destinations, destination => {
          const destinationKey = destination === 'Resumen' ? destinationList.inform : destination;

          return (
            <Form.Group key={destinationKey}>
              <Form.Check
                disabled={disabled || !verifyEnable(value, destinationKey)}
                type="checkbox"
                label={destination}
                checked={_.indexOf(value, destinationKey) >= 0}
                onChange={evt => {
                  if (evt.target.checked) {
                    onChange([...value, destinationKey]);
                  } else {
                    onChange(value.filter(dest => dest !== destinationKey));
                  }
                }}
              />
            </Form.Group>
          );
        })}
      </Col>
    </Row>
  );
};
