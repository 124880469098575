import React from 'react';

import ListPage from '../../components/admin/ListPage';
import ElementPage from '../../components/admin/ElementPage';
import TagsPanel from './TagsPanel';

const model = 'tags';

const statusList = {
  '': 'Todos los estados',
  Activo: 'Activo',
  Normalizado: 'Normalizado',
  Inactivo: 'Inactivo'
};

const columns = [
  {
    text: 'Nombre',
    dataField: 'name'
  },
  {
    text: 'Estado',
    dataField: 'status'
  },
  {
    id: 'normalized',
    text: 'Normalizado',
    // sort: '$normalized>name',
    sort: 'normalized.name',
    dataField: elm => (elm.normalized && elm.normalized.name) || ''
  }
];

export default [
  <ListPage key={`${model}_list`} model={model} columns={columns} statusList={statusList} path={`/${model}`} />, // LIST
  <ElementPage key={`${model}_create`} model={model} Panel={TagsPanel} path={`/${model}/create`} />, // CREATE
  <ElementPage key={`${model}_edit`} model={model} Panel={TagsPanel} path={`/${model}/:_id`} /> // EDIT
];
