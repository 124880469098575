import _ from 'lodash';
import React, { useState, useContext, useCallback } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { navigate } from '@reach/router';

import App from '../../Services/App';
import Auth from '../../Services/Auth';
import { Store } from '../../Services';

import { FormContainer, FormGroup } from '../../components/Form';
import { Validators, Validate } from '../../utils/Validations';

import '../../components/admin/ElementPage.scss';

const labels = {
  oldPassword: 'Contraseña actual',
  newPassword: 'Contraseña nueva',
  repeatPassword: 'Repita la contraseña nueva'
};

export default () => {
  const [usr, _setUsr] = useState({ oldPassword: '', newPassword: '', repeatPassword: '' });
  const { state, dispatch } = useContext(Store);

  const [error, setError] = useState({});
  const [msgError, setMsgError] = useState({});

  const setUsr = useCallback((data = {}) => {
    setError({});
    setMsgError({});
    _setUsr(prev => ({ ...prev, ...data }));
  }, []);

  const validatePasswords = async (user, labels) => {
    const validation = {
      oldPassword: Validators.required(),
      newPassword: Validators.password(),
      repeatPassword: Validators.sequential([Validators.password(), Validators.equals('newPassword')])
    };
    return Validate(user, validation, labels);
  };

  const changePassword = async () => {
    const resultValidation = await validatePasswords(usr, labels);
    if (_.isEmpty(resultValidation)) {
      Auth.changePassword(state.user._id, usr.newPassword, usr.oldPassword).then(() => {
        App.addNotification({ message: 'Contraseña cambiada exitosamente', variant: 'success' });
        navigate('/');
      });
    } else {
      App.addNotification({ message: 'Falta completar campos obligatorios' });
      setError(resultValidation);
    }
  };
  const handleBackButton = async () => {
    const { firstSession } = state.user;
    if (!firstSession) {
      window.history.back();
      return;
    }
    Auth.logout(dispatch);
  };

  return (
    <>
      <Container className="app-element-page" fluid>
        <Row className="panel">
          <Col lg={{ span: 6, offset: 3 }}>
            <Row>
              <Col xs="12">
                <FormContainer form={usr} onChange={setUsr} error={error} labels={labels}>
                  <FormGroup name="oldPassword" type="password" required />
                  <FormGroup name="newPassword" type="password" required />
                  <FormGroup name="repeatPassword" type="password" required />
                </FormContainer>
              </Col>
            </Row>

            {msgError && (
              <Row>
                <Col xs="12">
                  <Form.Label>{msgError.error}</Form.Label>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <Row className="button-control">
          <Col xs="12">
            <Button onClick={changePassword} disabled={false}>
              Cambiar Contraseña
            </Button>
            <Button onClick={handleBackButton} variant="secondary" disabled={false}>
              Volver
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};
