export default [
  {
    scope: 'contents',
    name: 'Contenido',
    goTo: '/contents',
    icon: 'reader-outline'
  },
  // {
  //   scope: 'inform',
  //   name: 'Resumenes',
  //   goTo: '/inform',
  //   icon: 'reader-outline'
  // },
  {
    scope: 'tags',
    name: 'Tags',
    goTo: '/tags',
    icon: 'pricetags-outline'
  },
  {
    scope: 'medium',
    name: 'Medios de Prensa',
    goTo: '/medium',
    icon: 'newspaper-outline'
  },
  {
    scope: 'users',
    name: 'Usuarios',
    goTo: '/users',
    icon: 'people-circle-outline'
  },
  {
    scope: 'roles',
    name: 'Roles',
    goTo: '/roles',
    icon: 'lock-closed-outline'
  },
  {
    scope: 'audit',
    name: 'Trazabilidad de Contenidos',
    goTo: '/audit',
    icon: 'reader-outline'
  },
  {
    scope: 'contents',
    name: 'Cambiar Contraseña',
    goTo: '/password',
    icon: 'key-outline'
  },
  {
    scope: 'versions',
    name: 'Versiones App',
    goTo: '/versions',
    icon: 'albums-outline'
  }
];
