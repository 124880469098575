import { navigate } from '@reach/router';

import App from './App';
import * as Api from '../Api';

const baseAction = '$Auth_';

const ACTIONS = {
  LOGGED_IN: `${baseAction}LOGGED_IN`,
  LOGIN_ERROR: `${baseAction}LOGIN_ERROR`
};

export const initialState = {
  user: undefined,
  role: undefined,
  errorLogin: undefined
};

export const reducer = (state, { type, payload } = {}) => {
  if (type === ACTIONS.LOGGED_IN) {
    return {
      ...state,
      user: payload.user,
      role: payload.role,
      errorLogin: undefined
    };
  }

  if (type === ACTIONS.LOGIN_ERROR) {
    return {
      ...state,
      errorLogin: payload,
      user: undefined,
      role: undefined
    };
  }

  return state;
};

const login = async (dispatch, username, password) => {
  try {
    const res = await Api.login(username, password);
    dispatch({
      type: ACTIONS.LOGGED_IN,
      payload: res
    });
    localStorage.setItem('token', res.token);
    navigate('/');
  } catch (err) {
    return dispatch({
      type: ACTIONS.LOGIN_ERROR,
      payload: err.response.data
    });
  }
};

const syncLoggedIn = async dispatch => {
  try {
    const res = await Api.loginSync();
    return dispatch({
      type: ACTIONS.LOGGED_IN,
      payload: res
    });
  } catch (err) {
    if (err.statusCode === 401)
      return dispatch({
        type: ACTIONS.LOGGED_IN,
        payload: {
          user: undefined,
          role: undefined
        }
      });

    App.addNotification({ message: err.message });
  }
};

const logout = dispatch => {
  localStorage.removeItem('token');
  dispatch({
    type: ACTIONS.LOGGED_IN,
    payload: {
      user: undefined,
      role: undefined
    }
  });
  navigate('/login');
};

const resetPassword = (id, password) => {
  return Api.privatePost(`auth/panel/reset-password/${id}`, { password });
};
const changePassword = async (_id, newPassword, oldPassword) => {
  try {
    const res = await Api.privatePost(`auth/change-password`, { _id, newPassword, oldPassword });
    App.addNotification({ message: res, variant: 'success' });
    return res;
  } catch (err) {
    App.addNotification({ message: err.business || err.message });
    throw err;
  }
};

export default {
  login,
  syncLoggedIn,
  logout,
  resetPassword,
  changePassword
};
