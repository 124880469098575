import axios from 'axios';
import _ from 'lodash';
import { navigate } from '@reach/router';

import App from './Services/App';

const apiBase = process.env.REACT_APP_SERVER;

function ApiException(statusCode = 404, error) {
  this.statusCode = statusCode;
  this.message = error.message;
  this.origin = error;
  this.business = _.get(error, 'response.data.message');
}

const privateREST = async (verb, endpoint, params, options = {}) => {
  const headers = options.headers || {};
  headers.Authorization = localStorage.getItem('token');
  if (!headers.Authorization) {
    navigate('/login');
    throw new Error('Error en la conexión');
  }
  const _axios = axios.create({ headers });

  try {
    let res;
    if (verb === 'delete') {
      res = await _axios.delete(`${apiBase}/${endpoint}`, { data: params });
    } else {
      res = await _axios[verb](`${apiBase}/${endpoint}`, params);
    }

    return res.data;
  } catch (err) {
    const statusCode = _.get(err, 'response.data.statusCode');
    if (statusCode === 401) {
      App.logout();
    }
    if (statusCode === 403) {
      App.addNotification({ message: 'Acceso No Permitido' });
      navigate('/');
    }
    throw new ApiException(statusCode, err);
  }
};

export const privateGet = (endpoint, options = {}) => privateREST('get', endpoint, undefined, options);
export const privatePost = (endpoint, params, options = {}) => privateREST('post', endpoint, params, options);
export const privatePut = (endpoint, params, options = {}) => privateREST('put', endpoint, params, options);
export const privateDelete = (endpoint, params, options = {}) => privateREST('delete', endpoint, params, options);

const filterQuery = query =>
  Object.keys(query).filter(key => query[key] !== undefined && query[key] !== null && query[key] !== '');

const toString = value => encodeURIComponent(_.isObject(value) ? JSON.stringify(value) : value);

const addQueryParams = (endpoint = '', query = {}) => {
  const q = filterQuery(query);
  const result = `${endpoint}${
    q && q.length ? `?${q.map(key => `${toString(key)}=${toString(query[key])}`).join('&')}` : ''
  }`;

  return result;
};

export const login = async (username, password) => {
  const data = await axios.post(`${apiBase}/auth/panel/login`, { username, password });
  return data.data;
};

export const loginSync = () => privateGet('auth/panel/sync');

export const findAll = (resource, query = {}) => privateGet(addQueryParams(`v1/${resource}`, query));

export const getOne = (resource, id) => privateGet(`v1/${resource}/${id}`);

export const create = (resource, params) => privatePost(`v1/${resource}`, params);

export const update = (resource, id, params) => privatePut(`v1/${resource}/${id}`, params);

export const remove = (resource, id) => privateDelete(`v1/${resource}/${id}`);

export const uploadFile = file => {
  const formData = new FormData();
  formData.append('file', file);
  return privatePost('v1/file', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
};

const optionsDefault = {
  baseUrl: 'v1/'
};

export default {
  private: {
    get: async (endpoint, query, options) => {
      options = _.merge(options || {}, optionsDefault);
      return privateGet(addQueryParams(`${options.baseUrl}${endpoint}`, query), options);
    },

    post: async (endpoint, body, options) => {
      options = _.merge(options || {}, optionsDefault);
      return privatePost(`${options.baseUrl}${endpoint}`, body, options);
    },

    put: async (endpoint, body, options) => {
      options = _.merge(options || {}, optionsDefault);
      return privatePut(`${options.baseUrl}${endpoint}`, body, options);
    },

    delete: async (endpoint, body, options) => {
      options = _.merge(options || {}, optionsDefault);
      return privateDelete(`${options.baseUrl}${endpoint}`, body, options);
    },

    uploadFile: async file => {
      return uploadFile(file);
    }
  }
};
