import App from './App';

import * as Api from '../Api';

const callCallback = (cb, res) => {
  if (cb) {
    cb(res);
  }
};

const callError = (err, cb, message) => {
  if (cb) {
    cb(err);
  } else {
    App.addNotification({ message });
  }
};

export const createCRUD = (model, ACTIONS) => {
  const findAll = async (
    dispatch,
    { search, page, limit, sort, exclude, pagination, filter } = {},
    { messageError, cbError, cb, throwError } = {}
  ) => {
    try {
      const res = await Api.findAll(model, { search, page, limit, sort, exclude, pagination, filter });

      if (dispatch) {
        dispatch({
          type: ACTIONS.FIND_ALL,
          payload: res
        });
      }

      callCallback(cb, res);

      return res;
    } catch (err) {
      if (throwError) throw err;
      callError(err, cbError, messageError || err.business || err.message);
    }
  };

  const getOne = async (dispatch, id, { messageError, cbError, cb, throwError } = {}) => {
    try {
      const res = await Api.getOne(model, id);
      dispatch({
        type: ACTIONS.GET,
        payload: res.data
      });

      callCallback(cb, res);

      return res;
    } catch (err) {
      if (throwError) throw err;
      callError(err, cbError, messageError || err.business || err.message);
    }
  };

  const cleanSelected = async dispatch => {
    return dispatch({
      type: ACTIONS.GET,
      payload: undefined
    });
  };

  const create = async (params, { messageError, cbError, cb, throwError } = {}) => {
    try {
      const res = await Api.create(model, params);

      callCallback(cb, res);

      return res;
    } catch (err) {
      if (throwError) throw err;
      callError(err, cbError, messageError || err.business || err.message);
    }
  };

  const update = async (dispatch, id, params, { messageError, cbError, cb, throwError } = {}) => {
    try {
      const res = await Api.update(model, id, params);
      dispatch({ type: ACTIONS.GET, payload: res.data });

      callCallback(cb, res);

      return res;
    } catch (err) {
      if (throwError) throw err;
      callError(err, cbError, messageError || err.business || err.message);
    }
  };

  const updateStatus = async (dispatch, id, params, { messageError, cbError, cb, throwError } = {}) => {
    try {
      const res = await Api.update(`${model}/status`, id, params);
      dispatch({ type: ACTIONS.GET, payload: res.data });

      callCallback(cb, res);

      return res;
    } catch (err) {
      if (throwError) throw err;
      callError(err, cbError, messageError || err.business || err.message);
    }
  };

  const remove = async (id, { messageError, cbError, cb, throwError } = {}) => {
    try {
      const res = await Api.remove(model, id);

      callCallback(cb, res);

      return res.data;
    } catch (err) {
      if (throwError) throw err;
      callError(err, cbError, messageError || err.business || err.message);
    }
  };

  return {
    findAll,
    getOne,
    create,
    update,
    updateStatus,
    remove,
    cleanSelected
  };
};
