import App from './App';

import * as Api from '../Api';

const uploadFile = async (file, { messageError, cbError, cb, throwError } = {}) => {
  try {
    const res = await Api.uploadFile(file);

    if (cb) {
      cb(res);
    }

    return res;
  } catch (err) {
    if (throwError) {
      throw err;
    }

    if (cbError) {
      cbError(err);
    } else {
      App.addNotification({ message: messageError || err.business || err.message });
    }
  }
};

export default {
  uploadFile
};
