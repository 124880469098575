import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { css } from 'emotion';

export default ({ content, setter, disabled, limit }) => {
  const [counter, setCounter] = useState(content.title ? content.title.length : 0);

  const cssCounter = css`
    color: #8f9498;
    font-size: 1em;
    float: right;
  `;

  const setTitle = evt => {
    if (evt.target.value.length <= limit) {
      setter({ title: evt.target.value });
      setCounter(evt.target.value.length);
    }
  };
  return (
    <>
      <Form.Control disabled={disabled} type="text" value={content.title} onChange={setTitle} />
      <span className={cssCounter}>
        {counter}/{limit}
      </span>
    </>
  );
};
