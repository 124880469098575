import React from 'react';
import { css } from 'emotion';

import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import './RichEditor.scss';

const Clipboard = Quill.import('modules/clipboard');
const Delta = Quill.import('delta');

class PlainClipboard extends Clipboard {
  onPaste(e) {
    e.preventDefault();
    const range = this.quill.getSelection();
    const text = e.clipboardData.getData('text/plain');
    const delta = new Delta().retain(range.index).delete(range.length).insert(text);
    const index = text.length + range.index;
    const length = 0;
    this.quill.updateContents(delta, 'silent');
    this.quill.setSelection(index, length, 'silent');
    this.quill.scrollIntoView();
  }
}

Quill.register('modules/clipboard', PlainClipboard, true);

const cssContainer = css`
  width: 100%;
`;

export default ({ value, onChange, disabled }) => {
  return <ReactQuill theme="snow" value={value} onChange={onChange} className={cssContainer} readOnly={disabled} />;
};
