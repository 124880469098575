import _ from 'lodash';
import React, { useState, useCallback } from 'react';

import Multiselect from 'react-widgets/lib/Multiselect';
import 'react-widgets/dist/css/react-widgets.css';

import logger from '../../../../logger';

import AppService from '../../../../Services/App';

import { useAction } from '../../../../helper/Hooks';
import Actions from '../actions';

export default ({ content, setter, disabled }) => {
  const createTag = useCallback(
    async newTag => {
      try {
        await Actions.createTag(newTag);
        setter({ tags: [...(content.tags || []), newTag] });
      } catch (err) {
        AppService.addNotification({ message: err.business || 'No se pudo crear nuevo tag' });
        logger.error(err);
      }
    },
    [setter, content.tags]
  );

  const [search, setSearch] = useState();
  const { data } = useAction(Actions.findTags, search);

  return (
    <Multiselect
      disabled={disabled}
      value={content.tags}
      data={_.map(data, 'name')}
      onChange={tags => setter({ tags })}
      filter={() => true}
      onSearch={setSearch}
      allowCreate="onFilter"
      onCreate={createTag}
      messages={{
        createOption: 'crear nuevo tag',
        emptyList: 'sin tags'
      }}
    />
  );
};
