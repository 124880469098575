import React from 'react';

import ListPage from '../../components/admin/ListPage';
import ElementPage from '../../components/admin/ElementPage';
import MediumPanel from './MediumPanel';

const model = 'medium';

const statusList = {
  '': 'Todos los estados',
  Activo: 'Activo',
  Inactivo: 'Inactivo'
};

const columns = [
  {
    text: 'Tipo',
    dataField: 'type'
  },
  {
    text: 'Nombre',
    dataField: 'name'
  },
  {
    text: 'Estado',
    dataField: 'status'
  }
];

export default [
  <ListPage key={`${model}_list`} model={model} columns={columns} path={`/${model}`} statusList={statusList} />, // LIST
  <ElementPage key={`${model}_create`} model={model} Panel={MediumPanel} path={`/${model}/create`} />, // CREATE
  <ElementPage key={`${model}_edit`} model={model} Panel={MediumPanel} path={`/${model}/:_id`} /> // EDIT
];
