import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';

import logger from '../../../logger';

import Actions from './actions';

const defaultControlButtonList = [
  {
    label: 'Rehabilitación a Revisión',
    condition: (permission, content, role, status) => {
      return status === 'Despublicado' && role === 'editor';
    },
    action: async (content, refresh, enabled, role) => {
      const errors = await Actions.execute('Rehabilitacion', content, enabled, role);
      if (!errors) {
        await refresh();
      }
    }
  },
  {
    label: 'Finalizar carga de sub-contenidos',
    condition: (permission, content, role, status) => {
      const acceptChildren = content ? content.acceptChildren : false;
      return (
        status === 'Publicado' &&
        content.hasChildren &&
        content.hasChildren.total > 0 &&
        acceptChildren &&
        role === 'editor'
      );
    },
    action: async (content, refresh, enabled, role) => {
      const errors = await Actions.execute('acceptChildren', content, enabled, role);
      if (!errors) {
        await refresh();
      }
    }
  },
  {
    label: 'Despublicar',
    condition: (permission, content, role, status) => {
      return status === 'Publicado' && role === 'editor';
    },
    action: async (content, refresh, enabled, role) => {
      const errors = await Actions.execute('Despublicado', content, enabled, role);
      if (!errors) {
        await refresh();
      }
    }
  },
  {
    label: 'Volver a pendiente',
    condition: (permission, content, role, status) => {
      return status === 'Revision' && role === 'editor';
    },
    action: async (content, refresh, enabled, role) => {
      const errors = await Actions.execute('Pendiente', content, enabled, role);
      if (!errors) {
        await refresh();
      }
    }
  },
  {
    label: 'Rechazar',
    condition: (permission, content, role, status) => {
      return status === 'Revision' && role === 'editor';
    },
    action: async (content, refresh, enabled, role) => {
      const errors = await Actions.execute('Rechazado', content, enabled, role);
      if (!errors) {
        await refresh();
      }
    }
  },
  {
    label: 'Publicar y Volver',
    condition: (permission, content, role, status) => {
      return (status === 'Revision' || status === 'Rehabilitacion') && role === 'editor';
    },
    action: async (content, refresh, enabled, role) => {
      const errors = await Actions.execute('Publicado', content, enabled, role);
      if (!errors) {
        await refresh();
        return { goBack: true };
      }
    }
  },
  {
    label: 'Guardar y Volver',
    condition: (permission, content, role, status) => {
      return status === 'Pendiente' && permission.createOrUpdate('contents', content._id);
    },
    action: async (content, refresh, enabled, role) => {
      return Actions.execute('saveAndBack', content, enabled, role);
    }
  },
  {
    variant: 'danger',
    label: 'Eliminar',
    condition: (permission, content, role, status) => {
      const canDelete = permission.delete('contents');

      return (
        canDelete &&
        ((role === 'editor' && (status === 'Revision' || status === 'Rehabilitacion')) ||
          (content._id !== undefined && status === 'Pendiente'))
      );
    },
    action: async content => {
      return Actions.deleteContent(content);
    }
  },
  {
    variant: 'secondary',
    label: 'Volver',
    action: async () => {
      window.history.back();
    }
  }
];

const local = {
  isRunning: false
};

const execAction = async (action, content, refresh, enabled, role, setLoading, setModal) => {
  if (!local.isRunning) {
    let result;
    local.isRunning = true;

    try {
      setLoading(true);
      result = await action(content, refresh, enabled, role);
      if (result) {
        if (result.modal) {
          setModal(result.modal);
        }
        if (result.goBack) {
          window.history.back();
        }
      }

      if (!result || !result.goBack) setLoading(false);
    } catch (err) {
      logger.log(err);
    }

    if (!result || !result.goBack) local.isRunning = false;
  }
};

const ControlButtons = function ({ controlButtonList, content, setLoading, loading, onRefresh, enabled, role }) {
  const [modal, setModal] = useState();

  useEffect(() => {
    local.isRunning = false;
  }, []);

  return (
    <>
      <Modal show={modal !== undefined} onHide={() => setModal()}>
        <Modal.Header closeButton>
          <Modal.Title>{modal ? modal.title : ''}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modal && <modal.Component {...{ content, setLoading, onClose: () => setModal(), ...(modal.props || {}) }} />}
        </Modal.Body>
      </Modal>

      <Row className="button-control">
        <Col xs="12">
          {_.map(controlButtonList || [], ({ variant = 'success', label, action }, idx) => (
            <Button
              disabled={loading && local.counter > 0}
              key={idx}
              variant={variant}
              onClick={async () => {
                execAction(action, content, onRefresh, enabled, role, setLoading, setModal);
              }}
            >
              {label}
            </Button>
          ))}
        </Col>
      </Row>
    </>
  );
};

ControlButtons.filter = (permission, content, role) => {
  const status = (content && content.status) || 'Pendiente';

  return _.filter(
    defaultControlButtonList,
    button => !button.condition || button.condition(permission, content, role, status)
  );
};

export default ControlButtons;
