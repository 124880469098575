import _ from 'lodash';
import React, { useState, useEffect, forwardRef, useImperativeHandle, useCallback } from 'react';
import { Row, Col, Form, Modal, Container, Button } from 'react-bootstrap';
import Roles from '../../Services/Roles';
import { required, min, password, email, phone } from '../../helper/Form';
import { Validate, Validators } from '../../utils/Validations';
import { FormContainer, FormGroup } from '../../components/Form';

import AuthActions from '../../Services/Auth';
import App from '../../Services/App';

const initial = {};

const statusList = ['PendienteVerificar', 'Pendiente', 'Activo', 'Inactivo'];
const labelPass = {
  password: 'Contraseña'
};

const ResetPasswordPanel = ({ id, setViewResetPassword }) => {
  const [password, _setPassword] = useState({ password: '' });
  const [error, setError] = useState({});
  const setPassword = useCallback((data = {}) => {
    setError({});
    _setPassword(prev => ({ ...prev, ...data }));
  }, []);

  const validatePasswords = async (user, labels) => {
    const validation = {
      password: Validators.password()
    };
    return Validate(user, validation, labels);
  };

  const resetPass = async () => {
    try {
      const resultValidation = await validatePasswords(password, labelPass);
      if (_.isEmpty(resultValidation)) {
        AuthActions.resetPassword(id, password.password).then(() => {
          App.addNotification({ message: 'Contraseña cambiada exitosamente', variant: 'success' });
          setViewResetPassword(false);
        });
      } else {
        App.addNotification({ message: 'Falta completar campos obligatorios' });
        setError(resultValidation);
      }
    } catch (err) {
      App.addNotification({ message: err.message });
    }
  };
  return (
    <Container fluid>
      <Row>
        <Col xs="12">
          <FormContainer form={password} onChange={setPassword} error={error} labels={labelPass}>
            <FormGroup name="password" type="text" />
          </FormContainer>
        </Col>

        <Col xs="12" style={{ paddingTop: '1.5rem' }}>
          <Button onClick={resetPass} variant="secondary">
            Cambiar Contraseña
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default forwardRef(({ selected, dispatch, state, permission }, ref) => {
  const [elm, setElement] = useState(initial);
  const [viewResetPassword, setViewResetPassword] = useState(false);

  const evt =
    (field, handle, key = 'value') =>
    evt => {
      setElement({ ...elm, [field]: handle ? handle(evt.target[key]) : evt.target[key] });
    };
  // const setter = field => value => setElement({ ...elm, [field]: value });
  const canResetPassword = permission.post('/panel/reset-password/', '/auth');

  const handleUsername = username => {
    return username.replace(/ /g, '');
  };
  const handlePhone = phone => {
    let phoneTransform = phone.replace(/[a-zA-Z ]/g, '');
    phoneTransform = phoneTransform.replace(/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/, '');
    return phoneTransform;
  };

  useImperativeHandle(ref, () => ({
    validate: async () => {
      let result = 0;
      result += required(elm.username, 'El Usuario es requerido');
      result += min(elm.username, 6, 'El Usuario debe estar compuesto de 6 caracteres como mínimo');
      result += required(elm.firstName, 'El Nombre es requerido');
      result += required(elm.lastName, 'El Apellido es requerido');
      result += email(elm.email);
      result += phone(elm.phone);
      if (!selected) {
        result += password(elm.password);
      }
      return result;
    },

    getParams: async () => {
      if (!elm.role) elm.role = state.roles.list[0].name;
      return elm;
    }
  }));

  useEffect(() => {
    if (selected) {
      setElement(selected);
    }
  }, [selected]);

  useEffect(() => {
    Roles.findAll(dispatch, { pagination: false }, { messageError: 'Error al cargar Roles' });
  }, [dispatch]);

  return (
    <>
      <Modal show={viewResetPassword} onHide={() => setViewResetPassword(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Cambiar Contraseña</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ResetPasswordPanel id={elm._id} setViewResetPassword={setViewResetPassword} />
        </Modal.Body>
      </Modal>

      <Col xs="12">
        <Form.Label>Usuario</Form.Label>
        <Form.Control
          disabled={selected !== undefined}
          type="text"
          value={elm.username}
          onChange={evt('username', handleUsername)}
        />
      </Col>

      <Col xs="12">
        <Form.Label>Nombre</Form.Label>
        <Form.Control type="text" value={elm.firstName} onChange={evt('firstName')} />
      </Col>

      <Col xs="12">
        <Form.Label>Apellido</Form.Label>
        <Form.Control type="text" value={elm.lastName} onChange={evt('lastName')} />
      </Col>

      {!selected && (
        <Col xs="12">
          <Form.Label>Contraseña</Form.Label>
          <Form.Control type="text" value={elm.password} onChange={evt('password')} />
        </Col>
      )}

      {selected && canResetPassword && (
        <Col xs="12" style={{ paddingTop: '1.5rem' }}>
          <Button onClick={() => setViewResetPassword(true)} variant="secondary">
            Cambiar Contraseña
          </Button>
        </Col>
      )}

      {selected && (
        <Col xs="12">
          <Form.Label>Estado</Form.Label>
          <Form.Control as="select" value={elm.status} onChange={evt('status')}>
            {_.map(statusList || [], name => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </Form.Control>
        </Col>
      )}

      <Col xs="12">
        <Form.Label>Rol</Form.Label>
        <Form.Control as="select" value={elm.role} onChange={evt('role')}>
          {_.map(state.roles.list || [], ({ name }) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </Form.Control>
      </Col>

      <Col xs="12">
        <Form.Label>Autoridad Superior</Form.Label>
        <Form.Control type="text" value={elm.boss} onChange={evt('boss')} />
      </Col>

      <Col xs="12">
        <Form.Label>
          Email <small>{selected ? `estado=${selected.emailStatus}` : ''}</small>
        </Form.Label>
        <Form.Control placeholder="ejemplo@ejemplo.com" type="email" value={elm.email} onChange={evt('email')} />
      </Col>

      <Col xs="12">
        <Form.Label>
          Teléfono celular <small>{selected ? `estado=${selected.phoneStatus}` : ''}</small>
        </Form.Label>
        <Form.Control
          placeholder="[Cód.Area + Número de teléfono (sin prefijo 15)] 11 1234-1234"
          type="text"
          value={elm.phone}
          onChange={evt('phone', handlePhone)}
        />
      </Col>
    </>
  );
});
