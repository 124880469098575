import React, { useState } from 'react';

import DropdownList from 'react-widgets/lib/DropdownList';
import 'react-widgets/dist/css/react-widgets.css';

import { useAction } from '../../../../helper/Hooks';
import Actions from '../actions';

export default ({ content, setter, disabled }) => {
  const [search, setSearch] = useState();
  const { data } = useAction(Actions.findMediums, search);

  return (
    <DropdownList
      disabled={disabled}
      filter={() => true}
      value={content.medium}
      data={data}
      textField="name"
      onChange={() => {}}
      onSelect={({ _id, name, type, imageFileName }) =>
        setter({ medium: { name, id: _id, type, image: imageFileName } })
      }
      onSearch={setSearch}
      messages={{
        emptyList: 'sin medios de prensa'
      }}
    />
  );
};
