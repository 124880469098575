import _ from 'lodash';
import moment from 'moment';

import logger from '../../../logger';

import Api from '../../../Api';
import AppService from '../../../Services/App';
import { required } from '../../../helper/Form';
import { destinationList } from '../../../components/Destinations';

import ModalDeleteComponent from './ModalDeleteComponent';

const messages = {
  saveAndBack: {
    success: 'Contenido guardado!',
    error: 'No se pudo guardar este contenido.'
  },
  Publicado: {
    success: 'Contenido publicado!',
    error: 'No se pudo publicar este contenido'
  },
  Rechazado: {
    success: 'Contenido rechazado!',
    error: 'No se pudo rechazar este contenido'
  },
  Pendiente: {
    success: 'Contenido Modificado',
    error: 'No se pudo modificar este contenido'
  },
  Despublicado: {
    success: 'Contenido Despublicado!',
    error: 'No se pudo despublicar este contenido'
  },
  acceptChildren: {
    success: 'Contenido Actualizado!',
    error: 'No se pudo actualizar este contenido'
  },
  Rehabilitacion: {
    success: 'Contenido Actualizado!',
    error: 'No se pudo actualizar este contenido'
  }
};

const validate = async (content, role) => {
  let result = 0;

  result += required(content.title, 'El Título es requerido');
  // result += required(content.destinations, 'El destino son requerido');

  const destination = content.destinations && content.destinations[0] ? content.destinations[0] : undefined;

  result += required(destination, 'El Destino es requerido');

  if (destination) {
    if (destination === destinationList.alert) {
      result += role === 'editor' && required(content.tags, 'El/los Tags son requerido(s)');
      result += required(content.medium, 'El Medio de prensa es requerido');
    }
  }

  return result;
};

const limit = 25;

const deleteContent = async (content, action, newParentId) => {
  try {
    if (!content || !content._id) throw new Error('Cannot delete undefined id');

    if (content.hasChildren && content.hasChildren.total > 0 && !action) {
      return {
        modal: {
          Component: ModalDeleteComponent,
          title: 'Eliminar',
          props: { deleteContent }
        }
      };
    }

    await Api.private.delete(`contents/${content._id}`, { action, newParentId });
    window.history.back();
    AppService.addNotification({ message: 'Contenido eliminado!', variant: 'success' });
  } catch (err) {
    AppService.addNotification({ message: 'No se pudo eliminar' });
    logger.error(err);
  }
};

export default {
  /**
   * CONTENTS
   */
  getContent: async id => {
    if (!id) return {};

    const content = await Api.private.get(`contents/${id}`);
    return content ? content.data : {};
  },

  getChildren: async id => {
    if (!id) return { total: 0, list: [] };

    const result = await Api.private.get('contents', { pagination: false, filter: { 'parent.id': id } });
    return result || { total: 0, list: [] };
  },

  findParents: async (search, content, disabled) => {
    if (disabled) return [content.parent];

    const result = await Api.private.get('contents', {
      limit,
      search: search !== '' ? search : undefined,
      exclude: content._id,
      filter: { parent: { $exists: false }, acceptChildren: true }
    });
    return result ? result.list : [];
  },

  execute: async (type, content, wasEnabled, role) => {
    try {
      if (wasEnabled) {
        const {
          title,
          tags,
          sourceLink,
          summary,
          text,
          dateTime,
          destinations,
          medium,
          status,
          assessment,
          hasPN,
          parent,
          acceptChildren,
          createdAt
        } = content;

        if (type !== 'Rechazado') {
          const errors = await validate(content, role);
          if (errors > 0) {
            return errors;
          }
        }

        let localImageFileName = content.imageFileName;
        let localAttachFileName = content.attachFileName;

        if (content.imageFile) {
          localImageFileName = await Api.private.uploadFile(content.imageFile);
        }

        if (content.attachFile) {
          localAttachFileName = await Api.private.uploadFile(content.attachFile);
        }

        const toSave = {
          title,
          tags,
          sourceLink,
          summary,
          text,
          dateTime,
          destinations,
          medium,
          status: type === 'saveAndBack' ? 'Revision' : status,
          assessment,
          hasPN,
          parent,
          acceptChildren,
          dateTimeString: dateTime ? moment(dateTime).format('DD/MM/YYYY hh:mm A') : undefined,
          imageFileName: localImageFileName,
          attachFileName: localAttachFileName,
          createdAt
        };

        if (content._id) {
          await Api.private.put(`contents/${content._id}`, toSave);
        } else {
          await Api.private.post('contents', toSave);
        }
      }

      if (type === 'saveAndBack') {
        AppService.addNotification({ message: messages[type].success, variant: 'success' });
        return { goBack: true };
      }

      const data = type === 'acceptChildren' ? { acceptChildren: false } : { status: type };

      await Api.private.put(`contents/status/${content._id}`, data);
      AppService.addNotification({ message: messages[type].success, variant: 'success' });
    } catch (err) {
      const message = _.get(err, 'origin.response.data.message', messages[type].error);
      logger.error(err);
      AppService.addNotification({ message });
    }
  },

  deleteContent,

  /**
   * TAGS
   */
  findTags: async search => {
    const result = await Api.private.get('tags', {
      limit,
      search,
      filter: { status: 'Activo' }
    });
    return result ? result.list : [];
  },

  createTag: async newTag => {
    const result = await Api.private.post('tags', { name: newTag });
    return result;
  },

  /**
   * MEDIUM
   */
  findMediums: async search => {
    const result = await Api.private.get('medium', { limit, search, filter: { status: 'Activo' } });
    return result ? result.list : [];
  }
};
