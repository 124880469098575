import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from '@reach/router';
import { StoreProvider } from './Services';

import App from './App';

import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';

import Contents from './pages/Contents';
import Tags from './pages/Tags';
import Users from './pages/Users';
import Roles from './pages/Roles';
import Medium from './pages/Medium';
import Inform from './pages/Inform';
import Audit from './pages/Audit';
import Password from './pages/Password';
import Versions from './pages/Versions';
import TestBench from './pages/TestBench';

import './index.scss';
import 'moment';

ReactDOM.render(
  <StoreProvider>
    <Router>
      <LoginPage path="/login" />
      <App path="/">
        <HomePage path="/" />
        {Contents}
        {Tags}
        {Users}
        {Roles}
        {Medium}
        {Inform}
        {Audit}
        {Password}
        {Versions}
        {TestBench}
      </App>
    </Router>
  </StoreProvider>,
  document.getElementById('root')
);
