import React, { useState, useContext, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { navigate } from '@reach/router';

import { Store } from '../Services';
import Auth from '../Services/Auth';

import logo from '../img/logo.png';

export default () => {
  const { state, dispatch } = useContext(Store);

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  useEffect(() => {
    Auth.syncLoggedIn(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (state.user) {
      if (state.user.firstSession) {
        navigate('/password');
      } else {
        navigate('/');
      }
    }
  }, [state.user]);

  return (
    <Container>
      <Row className="login">
        <Col lg={{ span: 4, offset: 4 }}>
          <Row>
            <Col xs="12">
              <img className="logo" src={logo} alt="logo" />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Form>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Usuario</Form.Label>
                  <Form.Control type="text" value={username} onChange={evt => setUsername(evt.target.value)} />
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Contraseña</Form.Label>
                  <Form.Control type="password" value={password} onChange={evt => setPassword(evt.target.value)} />
                </Form.Group>

                {state.errorLogin && state.errorLogin.statusCode === 400 && (
                  <Form.Group controlId="formBasicError">
                    <Form.Text className="text-danger">Su usuario o contraseña son incorrectos</Form.Text>
                  </Form.Group>
                )}

                {state.errorLogin && state.errorLogin.statusCode === 401 && (
                  <Form.Group controlId="formBasicError">
                    <Form.Text className="text-danger">Usuario sin acceso</Form.Text>
                  </Form.Group>
                )}

                {state.errorLogin && state.errorLogin.statusCode === 403 && (
                  <Form.Group controlId="formBasicError">
                    <Form.Text className="text-danger">Usuario inactivo</Form.Text>
                  </Form.Group>
                )}
                <Button
                  variant="primary"
                  type="button"
                  onClick={() => {
                    Auth.login(dispatch, username, password);
                  }}
                >
                  Entrar
                </Button>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
