import _ from 'lodash';
import React, { useState, useContext, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { navigate } from '@reach/router';

import { Store } from '../../Services';
import Permission from '../../helper/Permission';
import ReactIonicons from '../../components/ReactIonicons';

import pages from './pages';

export default () => {
  const { state } = useContext(Store);

  const [menu, setMenu] = useState();

  useEffect(() => {
    if (state.role) {
      const permission = Permission.has(state.role);
      const _menu = [];
      _.each(pages, page => {
        if (permission.list(page.scope)) {
          _menu.push(page);
        }
      });

      setMenu(_menu);
    }
  }, [state.role]);

  return (
    <Container className="app-menu">
      <Row className="justify-content-md-center">
        <Col xs="auto">
          <h3>Menu</h3>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs="12" md="10" lg="8">
          {menu &&
            _.map(menu, (page, idx) => (
              <Row key={idx} className="element">
                <Col>
                  <Button variant="outline-primary" block onClick={() => navigate(page.goTo)}>
                    {page.icon && <ReactIonicons logo={page.icon} />} {page.name}
                  </Button>
                </Col>
              </Row>
            ))}
        </Col>
      </Row>
    </Container>
  );
};
