import _ from 'lodash';

import React, { useState } from 'react';
import { Row, Col, Form, Container, Button } from 'react-bootstrap';
import { navigate } from '@reach/router';
import logger from '../logger';

import Controller from '../Services/Test';
import App from '../Services/App';
import { Store } from '../Services';

const model = 'test';

const types = {
  sms: 'Mensaje de Texto',
  pn: 'Push Notification',
  email: 'Envío de Email'
};

const destinationLabel = {
  sms: 'Número de Telefono',
  pn: 'Username',
  email: 'Email'
};

const initial = { type: 'pn' };

const TestBench = () => {
  const { state } = React.useContext(Store);

  if (state.role.name !== 'admin') {
    App.addNotification({ message: 'Acceso No Permitido' });
    navigate('/');
  }

  const [elm, setElement] = useState(initial);

  const evt =
    (field, handle, key = 'value') =>
    _evt => {
      setElement({ ...elm, [field]: handle ? handle(_evt.target[key]) : _evt.target[key] });
    };

  const execute = async () => {
    try {
      const { type, destination, message } = elm;
      const res = await Controller.executeTest(type, destination, message);

      logger.log({ res });

      App.addNotification({ message: 'Enviado!', variant: 'success' });
    } catch (error) {
      logger.log(error);
      App.addNotification({ message: 'Error!' });
    }
  };

  return (
    <Container className="app-element-page" fluid>
      <Row className="panel">
        <Col xs="12" md="8">
          <Row>
            <Col xs="12">
              <Form.Label>Tipo</Form.Label>
              <Form.Control as="select" value={elm.type} onChange={evt('type')}>
                {_.map(types, (name, key) => (
                  <option key={key} value={key}>
                    {name}
                  </option>
                ))}
              </Form.Control>
            </Col>

            <Col xs="12">
              <Form.Label>{destinationLabel[elm.type]}</Form.Label>
              <Form.Control type="text" value={elm.destination} onChange={evt('destination')} />
            </Col>

            <Col xs="12">
              <Form.Label>Mensaje</Form.Label>
              <Form.Control type="text" value={elm.message} onChange={evt('message')} />
            </Col>

            <Col xs="12">
              <Button onClick={() => execute()}>Ejecutar</Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default [
  <TestBench key={`${model}_create`} path={`/${model}`} /> // CREATE
];
