import _ from 'lodash';

import App from '../Services/App';

export const required = (value, message) => {
  if (value === undefined || value === null || value === '' || (_.isArray(value) && value.length === 0)) {
    App.addNotification({ message });
    return 1;
  }
  return 0;
};
export const min = (value, limit, message) => {
  if (!value || value.length < limit) {
    App.addNotification({ message });
    return 1;
  }
  return 0;
};
export const password = value => {
  if (!/^(?=.*[\u0021-\u002b\u003c-\u0040])\S{6,16}$/.test(value)) {
    App.addNotification({
      message:
        'La contraseña debe estar compuesta por 6 caracteres como mínimo y debe contener al menos un caracter especial.(Ejemplos de caracteres especiales: *"#$%&()=?)'
    });
    return 1;
  }
  return 0;
};
export const email = value => {
  const exp = /\S+@\S+\.\S+/;

  if (!exp.test(value)) {
    App.addNotification({ message: 'El Email no es válido.Debe tener el formato ejemplo@ejemplo.com' });
    return 1;
  }
  return 0;
};
export const phone = value => {
  if (value && value.length < 10) {
    App.addNotification({
      message:
        'El Teléfono celular no es válido. Debe estar compuesto por 10 digitos y el formato es [código de area] + [número de telefono (sin prefijo 15)]. Ej:11 1234-1234'
    });
    return 1;
  }
  return 0;
};
export const validVersion = value => {
  const exp = /^(\d+).(\d+).(\d+)(-.*)?/;

  if (!exp.test(value)) {
    App.addNotification({ message: 'La versión debe tener el formato x.y.z' });
    return 1;
  }
  return 0;
};
