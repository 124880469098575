import _ from 'lodash';
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Col, Form } from 'react-bootstrap';

import FileUpload from '../../components/FileUpload';
import FileService from '../../Services/File';

import { required } from '../../helper/Form';

const typeList = [
  'grafica',
  'portales de internet',
  'redes sociales',
  'agencias de noticias',
  'diarios del interior',
  'radio',
  'televisión'
];
const statusList = ['Activo', 'Inactivo'];

const initial = { type: typeList[0], status: statusList[0] };

export default forwardRef(({ selected }, ref) => {
  const [elm, setElement] = useState(initial);

  const evt =
    (field, key = 'value') =>
    evt =>
      setElement({ ...elm, [field]: evt.target[key] });
  const setter = data => setElement({ ...elm, ...data });

  useImperativeHandle(ref, () => ({
    validate: async () => {
      let result = 0;
      result += required(elm.name, 'El nombre es requerido');
      return result;
    },

    getParams: async () => {
      let localImageFileName = elm.imageFileName;

      if (imageFile) {
        localImageFileName = await FileService.uploadFile(imageFile);
      }

      return {
        ...elm,
        imageFileName: localImageFileName
      };
    }
  }));

  useEffect(() => {
    if (selected) {
      setElement(selected);
    }
  }, [selected]);

  const [imageFile, setImageFile] = useState();

  const cleanImageFile = () => {
    setImageFile();
    setter({ imageFileName: undefined });
  };

  return (
    <>
      <Col xs="12">
        <Form.Label>Nombre</Form.Label>
        <Form.Control disabled={selected !== undefined} type="text" value={elm.name} onChange={evt('name')} />
      </Col>

      <Col xs="12">
        <Form.Label>Estado</Form.Label>
        <Form.Control as="select" value={elm.status} onChange={evt('status')}>
          {_.map(statusList, status => (
            <option key={status}>{status}</option>
          ))}
        </Form.Control>
      </Col>

      <Col xs="12">
        <Form.Label>Tipo</Form.Label>
        <Form.Control as="select" value={elm.type} onChange={evt('type')}>
          {_.map(typeList, a => (
            <option key={a}>{a}</option>
          ))}
        </Form.Control>
      </Col>

      {elm.type === typeList[0] && (
        <Col xs="12">
          <Col xs="12">
            <Form.Group>
              <Form.Check
                type="checkbox"
                label="Es Tapa de Síntesis de Prensa"
                checked={elm.isTape}
                onChange={evt => setter({ isTape: evt.target.checked })}
              />
            </Form.Group>
          </Col>
        </Col>
      )}

      <Col xs="12">
        <Form.Label>Imagen</Form.Label>
        <FileUpload
          accept="image/png , image/jpeg , image/jpg"
          value={elm.imageFileName}
          onChange={setImageFile}
          onCleanFile={cleanImageFile}
          forbiddenMsg={'Solo puede agregar imagenes con extensión jpg y png'}
        />
      </Col>
    </>
  );
});
