import _ from 'lodash';

const isEmpty = value => {
  return value === undefined || value === null || value === '' || (_.isArray(value) && value.length === 0);
};

const get = (form, field) => {
  return form ? form[field] : undefined;
};

const returnError = (label, labelDefault, props) => {
  if (!label) return labelDefault;

  return _.isFunction(label) ? label(props) : label;
};

export const Validators = {
  required: label => async (value, form, name, labels) => {
    if (isEmpty(value)) {
      return returnError(label, `${labels[name]} es requerido`, { value, form, name, labels });
    }

    return undefined;
  },

  equals: (fieldCompare, label) => async (value, form, name, labels) => {
    const valueCompare = get(form, fieldCompare);

    if (value !== valueCompare) {
      return returnError(label, `Debe ser igual que ${labels[fieldCompare]}`, { value, form, name, labels });
    }

    return undefined;
  },

  parallel: list => async (value, form, name, labels) =>
    Promise.all(_.map(list, func => Promise.resolve(func(value, form, name, labels)))),

  sequential: list => async (value, form, name, labels) => {
    const execSequentially = (promise, func) => promise.then(error => error || func(value, form, name, labels));
    return _.reduce(list, execSequentially, Promise.resolve());
  },

  password: label => async (value, form, name, labels) => {
    if (!/^(?=.*[\u0021-\u002b\u003c-\u0040])\S{6,16}$/.test(value)) {
      return returnError(
        label,
        `${labels[name]}. Debe estar compuesta por 6 caracteres como mínimo y debe contener al menos un caracter especial.(Ejemplos de caracteres especiales: *"#$%&()=?)`,
        { value, form, name, labels }
      );
    }
    return undefined;
  }
};

const process = async ({ validations, name, value }, form, labels) => {
  const listFn = _.isFunction(validations) ? [validations] : _.isArray(validations) ? validations : [];

  return Promise.all(_.map(listFn, validation => validation(value, form, name, labels)));
};

export const Validate = async (form, validationsList, labels = {}) => {
  // Unifying the input objects to improve the validation process.
  const formValidation = _.map(validationsList, (validations, name) => ({
    name,
    value: get(form, name),
    validations
  }));

  const processed = await Promise.all(
    _.flattenDeep(
      _.map(formValidation, async fieldData => {
        const errors = _.compact(await process(fieldData, form, labels));
        return { name: fieldData.name, errors: errors && errors.length > 0 ? errors : undefined };
      })
    )
  );

  return _.reduce(
    processed,
    (result, { name, errors }) => {
      if (errors) {
        result[name] = errors;
      }

      return result;
    },
    {}
  );
};
