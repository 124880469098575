import _ from 'lodash';
import React, { useState, useContext, useEffect } from 'react';
import { Container, Row, Col, Button, Form, Spinner } from 'react-bootstrap';
import { navigate } from '@reach/router';
import MdSearch from 'react-ionicons/lib/MdSearch';

import queryString from 'query-string';
import ReactTable from '../ReactTable';
import { Store } from '../../Services';
import Controller from '../../Controller';

import Permission from '../../helper/Permission';

import './ListPage.scss';

export default ({ model, columns, statusList, fluid, notSelectable, notSearch }) => {
  // const queryString = require('query-string');
  const { search, status } = queryString.parse(window.location.search);
  const { state, dispatch } = useContext(Store);
  const permission = Permission.has(state.role);
  const [loading, setLoading] = useState();
  const [controller, setController] = useState();
  const [page, setPage] = useState(0);
  const [limit] = useState(30);
  const [sort, setSort] = useState('-createdAt');
  const [prevSearch, setPrevSearch] = useState(search || undefined);
  const [_status, _setStatus] = useState(status || undefined);

  const handlePrevSearch = value => {
    if (prevSearch !== value) {
      setPrevSearch(value);
    }
  };

  useEffect(() => {
    setController(Controller(model));
    localStorage.removeItem('LAST_CONTENT_ID');
  }, [model]);

  useEffect(() => {
    if (controller) controller.cleanSelected(dispatch);
  }, [dispatch, controller]);

  useEffect(() => {
    const exec = async () => {
      setLoading(true);
      await controller.findAll(
        dispatch,
        { page, limit, sort, search, filter: status ? { status } : undefined },
        { messageError: 'Hubo un error al cargar los datos' }
      );
      setLoading(false);
    };

    if (controller) exec();
  }, [dispatch, page, limit, sort, search, controller, status]);

  const goToCreate = () => {
    controller.cleanSelected(dispatch);
    navigate(`/${model}/create`);
  };

  const changeSearch = _search => {
    const { pathname } = window.location;
    const { status } = queryString.parse(window.location.search);
    if (_search) {
      if (status && status !== '') {
        navigate(`${pathname}?search=${_search}&status=${status}`, { replace: true });
      } else {
        navigate(`${pathname}?search=${_search}`, { replace: true });
      }
      return;
    }

    if (status && status !== '') {
      navigate(`/${model}?status=${status}`, { replace: true });
    } else {
      navigate(`/${model}`, { replace: true });
    }
  };

  const changeStatus = _sts => {
    const { pathname } = window.location;
    const { search } = queryString.parse(window.location.search);
    if (search) {
      navigate(`${pathname}?search=${search}&status=${_sts}`, { replace: true });
    } else {
      navigate(`${pathname}?status=${_sts}`, { replace: true });
    }
    _setStatus(_sts);
  };

  return (
    <Container className="app-list-page" fluid>
      <Row>
        <Col xs="12" md={{ span: fluid ? 12 : 8, offset: fluid ? 0 : 2 }}>
          <Row className="control">
            {!notSearch && (
              <Col xs="12" md={statusList ? '6' : '11'} className="search-content">
                <Form.Control
                  type="text"
                  placeholder="Buscar"
                  value={prevSearch}
                  onKeyDown={evt => {
                    if (evt.keyCode === 13 && !loading) changeSearch(prevSearch);
                  }}
                  onChange={evt => handlePrevSearch(evt.target.value)}
                />

                <Button onClick={() => changeSearch(prevSearch)} disabled={loading}>
                  <MdSearch color="white" />
                </Button>
              </Col>
            )}
            {statusList && (
              <Col xs="12" md="5">
                <Form.Control
                  as="select"
                  value={_status}
                  onChange={evt => {
                    changeStatus(evt.target.value);
                    setPage(0);
                  }}
                >
                  {_.map(statusList, (value, key) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            )}
            <Col xs="12" md="1">
              {permission.post(model) && <Button onClick={goToCreate}>Crear</Button>}
            </Col>
          </Row>
          <Row className="table">
            <Col xs="12" style={loading ? { textAlign: 'center' } : {}}>
              {loading ? (
                <Spinner animation="border" variant="danger" />
              ) : (
                <>
                  {state[model].list && state[model].list.length > 0 ? (
                    <ReactTable
                      columns={columns}
                      data={state[model].list}
                      page={page}
                      pages={Math.ceil(state[model].total / limit)}
                      onPageChange={page => setPage(page)}
                      onRowSelected={({ _id }) => permission.get(model) && navigate(`/${model}/${_id}`)}
                      onSortChange={setSort}
                      notEye={notSelectable || !permission.get(model)}
                    />
                  ) : (
                    <div>Sin Datos</div>
                  )}
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
