import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';

import DropdownList from 'react-widgets/lib/DropdownList';
import 'react-widgets/dist/css/react-widgets.css';

import Actions from '../actions';

export default ({ content, setter, disabled }) => {
  const [search, _setSearch] = useState();
  const [data,setData] = useState();
  useEffect(()=>{
      Actions.findParents(search, content,disabled).then((data)=>{
      setData(data);
    })
   },[search]);


  return (
    <>
      <Form.Check
        disabled={disabled}
        type="checkbox"
        label="es Sub-Contenido"
        checked={content.parent !== undefined}
        onChange={evt => setter({ parent: evt.target.checked ? {} : undefined })}
      />
      {content.parent !== undefined && (
        <>
          <DropdownList
            disabled={disabled}
            filter={() => true}
            value={content.parent||''}
            data={data}
            textField={item => (item && item.title ? `${item.title} - ${item.dateTimeString}` : '')}
            onSelect={({ _id, title, dateTimeString, destinations, tags, medium }) =>
              setter({ parent: { title, id: _id, dateTimeString }, destinations, tags, medium })
            }
            onSearch={_setSearch}
            messages={{
              emptyList: 'sin contenidos'
            }}
          />
          {content._id && content.parent && content.parent.id && (
            <Button variant="link" href={`/contents/${content.parent.id}`}>
              Ir al padre
            </Button>
          )}
        </>
      )}
    </>
  );
};
